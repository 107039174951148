export const APPLICATION_STATUS_MAP = {
  SHORTLISTED: {
    label: 'Shortlisted',
    color: '#32B237',
  },
  DONE: {
    label: 'Done',
    color: '#32B237',
  },
  REJECTED: {
    label: 'Rejected',
    color: '#ED2F2F',
  },
  PENDING: {
    label: 'Pending',
    color: '#FFA500',
  },
  UNDER_EVALUATION: {
    label: 'Under Evaluation',
    color: '#FFA500',
  },
  ONBOARDED: {
    label: 'Onboarded',
    color: '#3F7DFF',
  },
  HIRED: {
    label: 'Finalised',
    color: '#327BB2',
  },
  INTERVIEW_COMPLETED: {
    label: 'Completed',
    color: '#32B237',
  },
  INTERVIEW_LAPSED: {
    label: 'Lapsed',
    color: '#888888',
  },
  INTERVIEW_REJECTED: {
    label: 'Rejected',
    color: '#ED2F2F',
  },
  APPLIED: {
    label: 'Applied',
    color: '#327BB2',
  },
  SAVED: {
    label: 'Saved',
    color: '#327BB2',
  },
  SCREENING_REJECTED: {
    label: 'Rejected',
    color: '#ED2F2F',
  },
  DROPPED: {
    label: 'Dropped',
    color: '#ED782F',
  },
};

export const APPLICANT_STATUS_CURRENT_STATES = {
  SAVED: 'Saved',
  APPLIED: 'Applied',
  SHORTLISTED: 'Shortlisted',
  INTERVIEW_STARTED: 'Interview Started',
  INTERVIEW_LAPSED: 'Lapsed',
  INTERVIEW_COMPLETED: 'Interview Completed',
  SCREENING_REJECTED: 'Screening Rejected',
  INTERVIEW_REJECTED: 'Interview Rejected',
  HIRED: 'Finalised',
  ONBOARDED: 'Onboarded',
  DROPPED: 'Dropped',
  PENDING: 'Pending',
  DONE: 'Done',
  UNDER_EVALUATION: 'Under Evaluation',
};
