import THREE_DOTS from './threeDots.svg';
import CROSS_ICON from './cross-button.svg';
import CROSS_ICON_BLUE from './crossButtonBlue.svg';
import RUPEE_ICON from './rupee-icon.svg';
import COURSE from './course.svg';
import COURSE_ACTIVE from './courseActive.svg';
import HOME from './home.svg';
import HOME_ACTIVE from './homeActive.svg';
import USER_SIDE from './userSide.svg';
import USER_SIDE_ACTIVE from './userSideActive.svg';
import ROLES from './roles.svg';
import ROLES_ACTIVE from './rolesActive.svg';
import PERMISSIONS from './permissions.svg';
import PERMISSIONS_ACTIVE from './permissionsActive.svg';
import ARROW_DOWN from './arrowDown.svg';
import ARROW_DOWN_WHITE from './arrowDownWhite.svg';
import ARROW_UP from './arrowUp.svg';
import ARROW_UP_WHITE from './arrowUpWhite.svg';
import USER from './user.svg';
import SAATHI_LOGO from './Saathi_Logo.svg';
import PLUS from './plus.svg';
import PENCIL from './pencil.svg';
import VIDEO_CAMERA from './video-camera.svg';
import VIDEO_CAMERA_BLACK from './videoCameraBlack.svg';
import CLOCK from './clock.svg';
import HALF_STAR from './halfStar.svg';
import STAR from './star.svg';
import LEFT_ARROW_BLACK from './left-arrow-black.svg';
import LEFT_ARROW_WHITE from './left-arrow-white.svg';
import NEXT_BUTTON from './next-button.svg';
import OPEN_BOOK from './open-book.svg';
import ASSESSMENT from './assessment.svg';
import ASSESSMENT_GREEN from './assessmentGreen.svg';
import EYE from './eye.svg';
import DELETE_ICON from './delete-icon.svg';
import DARK_PLUS from './dark-plus.svg';
import CSV_GRAY from './csvGray.svg';
import SEARCH_ICON from './searchIcon.svg';
import PAYMENTS from './payments.svg';
import PAYMENTS_ACTIVE from './payments_Active.svg';
import COPY from './copy.svg';
import COPY_BLUE from './copy-blue.svg';
import ORDERS from './orders.svg';
import ORDERS_ACTIVE from './orders_Active.svg';
import SAATHI_LOGO_LARGE from './saathiLogoLarge.svg';
import EMAIL_ACTIVE from './emailActive.svg';
import EMAIL from './email.svg';
import LOCK from './lock.svg';
import LOCK_ACTIVE from './lockActive.svg';
import HIDE from './hide.svg';
import SHOW from './show.svg';
import PREV_LEFT from './prevLeft.svg';
import NEXT_RIGHT from './nextRight.svg';
import ARROW_DOWN_LIGHT from './arrowDownLight.svg';
import ARROW_UP_LIGHT from './arrowUpLight.svg';
import CALENDAR_ICON from './calendarIcon.svg';
import DOWNLOAD from './download.svg';
import DOWNLOAD_GRAY from './downloadGray.svg';
import MESSAGE_ICON from './message_icon.svg';
import CALL_ICON from './call_icon.svg';
import VIDEO_CAMERA_BLUE from './video-camera-blue.svg';
import THUMBNAIL from './thumbnail.svg';
import THUMBNAIL_WHITE from './thumbnail-white.svg';
import VIDEO_CAMERA_GREEN from './video-camera-green.svg';
import VIDEO_PLACEHOLDER from './videoPlaceholder.svg';
import VIDEO_PLACEHOLDER_GREEN from './videoPlaceholderGreen.svg';
import VIDEO_UPLOADED from './videoUploaded.svg';
import SPEAKER_BLUE from './speaker-blue.svg';
import SPEAKER from './speaker.svg';
import SPEAKER_WHITE from './speaker-white.svg';
import VIDEO_CAMERA_WHITE from './video-camera-white.svg';
import CROSS_BUTTON_WHITE from './cross-button-white.svg';
import LOG_OUT from './logoutIcon.svg';
import INFO_ICON from './infoIcon.svg';
import INFO_ICON_GREY from './infoIconGrey.svg';
import GREEN_TICK from './greenTick.svg';
import RED_CROSS from './redCross.svg';
import OPEN_BOOK_GREEN from './openBookGreen.svg';
import THUMBNAIL_GREEN from './thumbnailGreen.svg';
import THUMBNAIL_GRAY from './thumbnailGray.svg';
import IMAGE_PLACEHOLDER from './imagePlaceholder.svg';
import IMAGE_PLACEHOLDER_GREEN from './imagePlaceholderGreen.svg';
import YELLOW_INFO from './yellowInfo.svg';
import INFO_TOOLTIP from './infoTooltip.svg';
import CHECKLIST from './checklist.svg';
import TROPHY from './trophy.svg';
import BADGE from './badge.svg';
import CERTIFICATE from './certificate.png';
import FILTER from './filter.svg';
import BLUE_TICK from './blueTick.svg';
import EMPLOYERS from './employers.svg';
import EMPLOYERS_ACTIVE from './employers_Active.svg';
import RUPEE from './rupee.svg';
import USERS_MANAGEMENT from './rolesMgt.svg';
import USERS_MANAGEMENT_ACTIVE from './rolesMgtActive.svg';
import PAYOUT_REQUEST from './payoutRequest.svg';
import GOLD_MEDAL from './goldMedal.svg';
import SILVER_MEDAL from './silverMedal.svg';
import BRONZE_MEDAL from './bronzeMedal.svg';
import SIDEBAR_DOWN from './sidebarDown.svg';
import SIDEBAR_UP from './sidebarUp.svg';
import ACCOUNTS_SMALL from './accountsSmall.svg';
import ACCOUNTS_SMALL_ACTIVE from './accountsSmallActive.svg';
import PAYOUT_SMALL from './payoutSmall.svg';
import PAYOUT_SMALL_ACTIVE from './payoutSmallActive.svg';
import EARNING_BACK from './earningBack.svg';
import VERIFIED from './verified.svg';
import INCOMING from './incomingInactive.svg';
import INCOMING_ACTIVE from './incomingActive.svg';
import REFUND from './refundInactive.svg';
import REFUND_ACTIVE from './refundActive.svg';
import UPLOADFILE from './uploadFile.svg';
import UPLOAD from './upload.svg';
import REFRESH from './refresh.svg';
import SAATHI_LOGO_NEW from './newSaathiLogo.svg';
import SAATHI_LOGO_NEW_WHITE from './newWhiteSaathiLogo.svg';
import CSV_BLUE from './csvBlue.svg';
import UPLOAD_GRAY from './uploadGray.svg';
import DIRECT_EMPLOYER from './directEmployer.svg';
import FACILITY_MANAGEMENT from './facilityManagement.svg';
import REC_AGENCY from './recAgency.svg';
import STAFFING_AGENCY from './staffingAgency.svg';
import JOBS from './jobs.svg';
import JOBS_INACTIVE from './jobsInactive.svg';
import UPLOAD_LOGO from './uploadLogo.svg';
import UPLOADING_LOGO from './uploadingLogo.svg';
import DOCUMENT from './document.svg';
import VIDEO_THUMBNAIL_GRAY from './videoThumbnailGray.svg';

const ICONS = {
  THREE_DOTS,
  CROSS_ICON,
  CROSS_ICON_BLUE,
  RUPEE_ICON,
  COURSE,
  COURSE_ACTIVE,
  HOME,
  HOME_ACTIVE,
  USER_SIDE,
  USER_SIDE_ACTIVE,
  ROLES,
  ROLES_ACTIVE,
  PERMISSIONS,
  PERMISSIONS_ACTIVE,
  ARROW_DOWN,
  ARROW_DOWN_WHITE,
  ARROW_UP,
  ARROW_UP_WHITE,
  USER,
  SAATHI_LOGO,
  PLUS,
  PENCIL,
  VIDEO_CAMERA,
  VIDEO_CAMERA_BLACK,
  CLOCK,
  HALF_STAR,
  STAR,
  LEFT_ARROW_BLACK,
  LEFT_ARROW_WHITE,
  NEXT_BUTTON,
  OPEN_BOOK,
  ASSESSMENT,
  EYE,
  DELETE_ICON,
  DARK_PLUS,
  CSV_GRAY,
  SEARCH_ICON,
  PAYMENTS,
  PAYMENTS_ACTIVE,
  COPY,
  ORDERS,
  ORDERS_ACTIVE,
  SAATHI_LOGO_LARGE,
  EMAIL_ACTIVE,
  EMAIL,
  LOCK,
  LOCK_ACTIVE,
  HIDE,
  SHOW,
  PREV_LEFT,
  NEXT_RIGHT,
  ARROW_DOWN_LIGHT,
  ARROW_UP_LIGHT,
  CALENDAR_ICON,
  DOWNLOAD,
  DOWNLOAD_GRAY,
  MESSAGE_ICON,
  CALL_ICON,
  VIDEO_CAMERA_BLUE,
  THUMBNAIL,
  VIDEO_CAMERA_GREEN,
  VIDEO_PLACEHOLDER,
  VIDEO_PLACEHOLDER_GREEN,
  VIDEO_UPLOADED,
  SPEAKER_BLUE,
  SPEAKER,
  THUMBNAIL_WHITE,
  THUMBNAIL_GRAY,
  SPEAKER_WHITE,
  VIDEO_CAMERA_WHITE,
  CROSS_BUTTON_WHITE,
  LOG_OUT,
  INFO_ICON,
  GREEN_TICK,
  RED_CROSS,
  ASSESSMENT_GREEN,
  OPEN_BOOK_GREEN,
  THUMBNAIL_GREEN,
  IMAGE_PLACEHOLDER,
  IMAGE_PLACEHOLDER_GREEN,
  YELLOW_INFO,
  INFO_TOOLTIP,
  COPY_BLUE,
  CHECKLIST,
  TROPHY,
  BADGE,
  CERTIFICATE,
  FILTER,
  BLUE_TICK,
  INFO_ICON_GREY,
  EMPLOYERS,
  EMPLOYERS_ACTIVE,
  RUPEE,
  USERS_MANAGEMENT,
  USERS_MANAGEMENT_ACTIVE,
  PAYOUT_REQUEST,
  GOLD_MEDAL,
  SILVER_MEDAL,
  BRONZE_MEDAL,
  SIDEBAR_DOWN,
  SIDEBAR_UP,
  ACCOUNTS_SMALL,
  ACCOUNTS_SMALL_ACTIVE,
  PAYOUT_SMALL,
  PAYOUT_SMALL_ACTIVE,
  EARNING_BACK,
  VERIFIED,
  INCOMING,
  INCOMING_ACTIVE,
  REFUND,
  REFUND_ACTIVE,
  UPLOADFILE,
  UPLOAD,
  UPLOAD_GRAY,
  REFRESH,
  SAATHI_LOGO_NEW,
  SAATHI_LOGO_NEW_WHITE,
  CSV_BLUE,
  DIRECT_EMPLOYER,
  REC_AGENCY,
  STAFFING_AGENCY,
  FACILITY_MANAGEMENT,
  JOBS,
  JOBS_INACTIVE,
  UPLOAD_LOGO,
  UPLOADING_LOGO,
  DOCUMENT,
  VIDEO_THUMBNAIL_GRAY,
};

export default ICONS;
